<template>
  <tr class="import-row">
    <WiListDataTableActions :config="config" :props="props"></WiListDataTableActions>

    <td class="id-column">
      <span>#{{ import_item.id }}</span>
    </td>

    <td class="name-column">
      <strong v-text="import_item.name"></strong>
    </td>

    <td class="type-column">
      <div class="type-container">
        <v-icon small class="type-icon">{{ getTypeIcon }}</v-icon>
        <span>{{ getTypeText }}</span>
      </div>
    </td>

    <td class="status-column">
      <div class="status-container">
        <v-chip :color="getStatusColor" small class="status-chip">
          <v-icon small left>{{ getStatusIcon }}</v-icon>
          {{ getStatusText }}
        </v-chip>
      </div>
    </td>

    <td class="progress-column">
      <div class="progress-container">
        <div class="progress-info">
          <span class="progress-text">
            Processados: <strong>{{ import_item.count_success + import_item.count_errors }} de {{ import_item.count_total }}</strong>
            <span class="percentage">({{ progressPercentage }}%)</span>
          </span>
          <v-progress-linear
            :value="progressPercentage"
            :color="getStatusColor"
            class="progress-bar"
          ></v-progress-linear>
          <div class="counter-info">
            <span class="success-count">
              <v-icon small color="green">done</v-icon>
              <strong>{{ import_item.count_success }}</strong>
            </span>
            <span class="error-count">
              <v-icon small color="red">close</v-icon>
              <strong>{{ import_item.count_errors }}</strong>
            </span>
          </div>
        </div>
      </div>
    </td>

    <td class="actions-column">
      <v-btn v-if="import_item.status_id === 'processing'" small icon @click="pauseImport()">
        <v-icon color="blue">pause</v-icon>
      </v-btn>
      <v-btn v-else-if="import_item.status_id === 'paused'" small icon @click="resumeImport()">
        <v-icon color="orange">play_arrow</v-icon>
      </v-btn>
      <v-btn v-if="import_item.type_id === 'contacts'" small icon @click="listTalkContacts()">
        <v-icon>person</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
export default {
  name: 'Imports',
  props: {
    config: Object,
    props: Object
  },
  computed: {
    import_item() {
      return this.props.item
    },
    progressPercentage() {
      const total = this.import_item.count_total
      const processed = this.import_item.count_success + this.import_item.count_errors
      return total > 0 ? Math.round((processed / total) * 100) : 0
    },
    getStatusColor() {
      const statusColors = {
        pending: 'grey',
        processing: 'orange',
        completed: 'success',
        error: 'error',
        paused: 'blue'
      }
      return statusColors[this.import_item.status_id] || 'grey'
    },
    getStatusIcon() {
      const statusIcons = {
        pending: 'schedule',
        processing: 'sync',
        completed: 'check_circle',
        error: 'error',
        paused: 'pause_circle'
      }
      return statusIcons[this.import_item.status_id] || 'help'
    },
    getStatusText() {
      const statusTexts = {
        pending: 'Pendente',
        processing: 'Processando',
        completed: 'Concluído',
        error: 'Erro',
        paused: 'Pausado'
      }
      return statusTexts[this.import_item.status_id] || 'Desconhecido'
    },
    getTypeIcon() {
      const typeIcons = {
        contacts: 'people',
        products: 'inventory_2'
      }
      return typeIcons[this.import_item.type_id] || 'help'
    },
    getTypeText() {
      const typeTexts = {
        contacts: 'Contatos',
        products: 'Produtos'
      }
      return typeTexts[this.import_item.type_id] || 'Desconhecido'
    }
  },
  methods: {
    pauseImport() {
      console.log('Pausar importação')
      this.$WiApiPut({
        uri: 'import/pause',
        id: this.import_item.id,
        data: {
          import_id: this.import_item.id
        },
        config: {
          msgLoad: {
            title: `Pausando importação...`,
            text: 'Aguarde alguns instantes...'
          },
          msgSucess: {
            title: 'Importação pausada com sucesso.',
            text: 'Você pode acompanhar o andamento na listagem de importações.'
          },
          msgError: {
            title: 'Erro ao processar importação',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {   
            title: `Tem certeza que deseja pausar a importação?`,
            text: 'Esta ação pode levar alguns minutos para ser concluída e não pode ser desfeita.'
          }
        },
        callback: ({ sucess }) => {
          if (sucess) {
            this.config.refresh()
          }
        }
      })
    },
    resumeImport() {
      console.log('Continuar importação')
      this.$WiApiPut({
          uri: 'import/resume',
          id: this.import_item.id,
          data: {
            import_id: this.import_item.id
          },
          config: {
            msgLoad: {
              title: `Continuando importação...`,
              text: 'Aguarde alguns instantes...'
            },
            msgSucess: {
              title: 'Importação continuada com sucesso.',
              text: 'Você pode acompanhar o andamento na listagem de importações.'
            },
            msgError: {
              title: 'Erro ao processar importação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja continuar a importação?`,
              text: 'Esta ação pode levar alguns minutos para ser concluída e não pode ser desfeita.'
            }
          },
          callback: ({ sucess }) => {
            if (sucess) {
              this.config.refresh()
            }
          }
        })
    },
    listTalkContacts() {
      this.$WiListDialog({
        wiConfig: 'talk-contact',
        filter: {
          import_id: this.import_item.id
        }
      })
    }
  },
  components: {
    WiListDataTableActions
  }
}
</script>

<style scoped>
.import-row {
  transition: background-color 0.2s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.import-row:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.theme--dark .import-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.theme--dark .import-row:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

td {
  padding: 12px 8px !important;
  vertical-align: middle;
  height: 28px !important;
}

.id-column {
  /* min-width: 70px; */
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
}

.theme--dark .id-column {
  color: rgba(255, 255, 255, 0.7);
}

.name-column, .type-column {
  /* max-width: 150px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-column {
  /* width: 120px; */
  text-align: left;
}

.status-container {
  display: flex;
  justify-content: left;
}

.status-chip {
  /* min-width: 90px; */
  justify-content: center;
}

.progress-column {
  min-width: 220px;
  max-width: 280px;
  padding: 1px 8px !important;
}

.progress-container {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.03);
}

.theme--dark .progress-container {
  background-color: rgba(255, 255, 255, 0.03);
}

.progress-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.progress-text {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: space-between;
}

.theme--dark .progress-text {
  color: rgba(255, 255, 255, 0.9);
}

.percentage {
  color: rgba(0, 0, 0, 0.6);
}

.theme--dark .percentage {
  color: rgba(255, 255, 255, 0.7);
}

.progress-bar {
  margin: 0 !important;
  border-radius: 2px;
  height: 4px !important;
}

.counter-info {
  display: flex;
  justify-content: space-around;
  font-size: 0.875rem;
}

.success-count, .error-count {
  display: flex;
  align-items: center;
  gap: 4px;
}

.actions-column {
  min-width: 80px;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
}

.theme--dark .actions-column {
  color: rgba(255, 255, 255, 0.5);
}

.type-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.type-icon {
  color: rgba(0, 0, 0, 0.6);
}

.theme--dark .type-icon {
  color: rgba(255, 255, 255, 0.7);
}
</style>